import React from "react";
import styles from "./SubmitPopup.module.scss";

const SubmitPopup = ({ setIsSubmitPopup }) => {
  return (
    <div className={styles.popup}>
      <div className={styles.header}>
        <svg
          width="38"
          height="38"
          viewBox="0 0 38 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.3">
            <path
              d="M6 19C6 11.8203 11.8203 6 19 6C26.1797 6 32 11.8203 32 19C32 26.1797 26.1797 32 19 32C11.8203 32 6 26.1797 6 19Z"
              stroke="#079455"
              strokeWidth="2"
            />
          </g>
          <g opacity="0.1">
            <path
              d="M1 19C1 9.05888 9.05888 1 19 1C28.9411 1 37 9.05888 37 19C37 28.9411 28.9411 37 19 37C9.05888 37 1 28.9411 1 19Z"
              stroke="#079455"
              strokeWidth="2"
            />
          </g>
          <g clip-path="url(#clip0_1097_4110)">
            <path
              d="M15.25 19.0003L17.75 21.5003L22.75 16.5003M27.3334 19.0003C27.3334 23.6027 23.6024 27.3337 19 27.3337C14.3976 27.3337 10.6667 23.6027 10.6667 19.0003C10.6667 14.398 14.3976 10.667 19 10.667C23.6024 10.667 27.3334 14.398 27.3334 19.0003Z"
              stroke="#079455"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_1097_4110">
              <rect
                width="20"
                height="20"
                fill="white"
                transform="translate(9 9)"
              />
            </clipPath>
          </defs>
        </svg>
        <svg
          onClick={() => setIsSubmitPopup(false)}
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ cursor: "pointer" }}
        >
          <path
            d="M11 1L1 11M1 1L11 11"
            stroke="#98A2B3"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div className={styles.content}>
        <h3 className="inter semiBold">Your submission is completed</h3>
        <p className="inter regular">
          We've received your information and will get back to you shortly. If
          you have any urgent inquiries, feel free to contact us directly at{" "}
          <a href="mailto:support@ovalife.com">Support@ovalife.com</a>
        </p>
      </div>
    </div>
  );
};

export default SubmitPopup;
