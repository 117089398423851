import React from "react";
import styles from "./Terms.module.scss";
import { Link } from "react-router-dom";

const Terms = () => {
  return (
    <>
      <section className={`section ${styles.heroSection}`}>
        <div className="container">
          <div className={styles.heroContent}>
            <h5 className="inter medium">Last updated: October 14th, 2024</h5>
            <h2>Terms and conditions</h2>
            {/* <p>
              We use some cookies to help improve your experience on this
              website. Here's some more info about what we do with information
              we collect.
            </p> */}
          </div>
        </div>
      </section>
      <section className={`section ${styles.contentSection}`}>
        <div className="container">
          <div className={styles.content}>
            <p className="inter regular">
              This agreement outlines the terms and conditions (“Terms”)
              governing your relationship with Ovalife ("OL," "we," "us," or
              "our") and your use of our website,{" "}
              <a href="https://www.ovalife.me/">www.ovalife.me</a> (the
              “Website”), our mobile app (the “Mobile App”), and any services or
              offerings available through them (collectively referred to as the
              “Services”). By using or accessing the Services, you agree to be
              bound by these Terms, including our Privacy Policy, which can be
              found at <Link to="/privacy-policy">Privacy Policy.</Link>
            </p>
            <br />
            <br />
            <h3 className="inter semiBold">
              Please read these Terms carefully
            </h3>
            <p>
              By using or accessing the Services, you confirm that you have
              read, understood, and agree to these Terms. If you do not agree,
              you are not permitted to use the Services.
            </p>
            <br />
            <h3 className="inter semiBold">
              Important Notice: Arbitration Clause
            </h3>
            <p>
              These Terms include provisions for resolving disputes through
              binding arbitration. Please refer to Section 17 for more
              information.
            </p>
            <br />
            <h3 className="inter semiBold">Medical Disclaimer</h3>
            <p>
              OL does not provide medical advice. We partner with various
              third-party reproductive organizations like fertility clinics and
              egg donation agencies (collectively referred to as “Clinics”), but
              we do not employ medical professionals. The Clinics are solely
              responsible for any medical services or advice you receive. Our
              Services are informational in nature and are not intended to
              replace medical evaluations by licensed practitioners. We assume
              no liability for medical services or advice provided by others.
            </p>
            <br />
            <h3 className="inter semiBold">Legal Disclaimer</h3>
            <p>
              OL makes no representations or warranties regarding legal rights
              related to the reproductive process, including issues surrounding
              egg donation, fertilization, or parental rights. Any verification
              or validation of donor or recipient eligibility is the
              responsibility of the Clinic, not OL. We do not guarantee any
              specific outcomes from using the Services.
            </p>
            <br />
            <h3 className="inter semiBold">Eligibility</h3>
            <p>
              The Services are available only to individuals who are 18 years or
              older. By accessing or using the Services, you represent that you
              meet this age requirement.
            </p>
            <br />
            <h3 className="inter semiBold">1. Role of Clinics</h3>
            <ul>
              <li>
                <p>
                  OL is not a medical provider, clinic, or healthcare
                  organization.
                </p>
              </li>
              <li>
                <p>We do not offer medical opinions, advice, or screenings.</p>
              </li>
              <li>
                <p>
                  Any injury or issue that arises from using the Clinic's
                  services is not the responsibility of OL.
                </p>
              </li>
            </ul>
            <br />
            <h3 className="inter semiBold">2. User Responsibilities</h3>
            <p>
              To access the Services, you must create and maintain a personal
              account. This includes providing accurate and up-to-date
              information such as your email, password, name, address, and
              payment details. You are responsible for securing your login
              credentials and immediately reporting any unauthorized account
              activity.
            </p>
            <p>You agree not to:</p>
            <ul>
              <li>
                <p>Impersonate others or misrepresent your identity.</p>
              </li>
              <li>
                <p>Share your account with anyone.</p>
              </li>
              <li>
                <p>
                  Access or share donor information or images with third
                  parties.
                </p>
                <p>
                  Use the Services in a way that violates any laws or
                  regulations.
                </p>
              </li>
            </ul>
            <br />
            <h3 className="inter semiBold">3. Payments</h3>
            <p>
              In cases where payments are processed through the Services, OL
              uses third-party providers to handle transactions. You agree that
              OL is not responsible for the third-party payment provider's
              failure to safeguard your financial information.
            </p>
            <br />
            <h3 className="inter semiBold">User Content</h3>
            <p>
              You may post or submit content through the Services, such as
              photos or text (“User Content”). You are solely responsible for
              ensuring your content is accurate and lawful. By submitting
              content, you grant OL the right to use it for various purposes,
              including marketing and sharing with Clinics. This includes the
              right to modify, display, or distribute your content.
            </p>
            <br />
            <h3 className="inter semiBold">5. Intellectual Property</h3>
            <p>
              All materials available through the Services, including text,
              images, videos, and designs, are protected by intellectual
              property laws. You are granted limited access to these materials
              solely for personal use. Any attempt to copy, modify, or
              reverse-engineer these materials is prohibited.
            </p>
            <br />
            <h3 className="inter semiBold">6. Limitations</h3>
            <p>
              You may not attempt to circumvent any security measures,
              reverse-engineer, or interfere with the Services in any way. Any
              unauthorized use of the Services is a violation of these Terms and
              may result in legal action.
            </p>
            <br />
            <h3 className="inter semiBold">7. Feedback</h3>
            <p>
              Any feedback or suggestions you provide to OL become our exclusive
              property. We may use this feedback without compensating you or
              acknowledging your contribution.
            </p>
            <br />
            <h3 className="inter semiBold">8. Suspension and Termination</h3>
            <p>
              OL reserves the right to terminate or suspend your account at any
              time, with or without notice, if we believe you have violated
              these Terms or if your account has been inactive. If your access
              is terminated, you are still responsible for any charges incurred
              prior to termination.
            </p>
            <br />
            <h3 className="inter semiBold">9. Third-Party Content</h3>
            <p>
              Our Services may include links to third-party websites or content,
              provided for your convenience. We do not monitor or evaluate the
              content of these external sites and make no representations or
              warranties regarding their services or information. If you choose
              to visit a linked third-party site, you do so at your own risk. We
              are not responsible for any content, products, or services
              accessed through these sites and disclaim any liability for
              damages arising from such access. The inclusion of these links
              does not mean we endorse or guarantee the site or its content. You
              also agree not to link to our Services without prior written
              permission from us.
            </p>
            <br />
            <h3 className="inter semiBold">10. Disclaimers and Warranties</h3>
            <p>
              Our Services are provided "as is," without any guarantees or
              warranties, whether express or implied. This includes, but is not
              limited to, warranties of merchantability, fitness for a
              particular purpose, non-infringement, and accuracy. We do not
              guarantee that our Services will meet your needs, be free of
              errors, or remain uninterrupted. Additionally, we do not promise
              that any defects will be corrected or that our Services, or any
              linked websites, will be free of viruses or other harmful
              components. We are not liable for any actions, errors, or
              omissions by third parties, including any associated with clinics
              or third-party providers. Please note that some state laws may not
              permit limitations on implied warranties, so certain exclusions
              may not apply to you. In such cases, you may have additional
              rights.
            </p>
            <br />
            <h3 className="inter semiBold">11. Indemnification</h3>
            <p>
              By using our Services, you agree to defend, indemnify, and hold
              harmless our company, affiliates, partners, officers, directors,
              employees, and members from any claims, liabilities, costs, and
              expenses (including legal fees) arising from your use of the
              Services. This includes any content you create, submit, or share
              through our Services and any violations of these Terms by you. We
              reserve the right to manage the defense of any such claims with
              legal counsel of our choosing.
            </p>
            <br />
            <h3 className="inter semiBold">12. Limitation of Liability</h3>
            <p>
              We are not liable for any lost profits, savings, data, business
              interruptions, or any indirect, special, incidental, punitive, or
              consequential damages that arise from your use of our Services.
              This limitation applies even if we’ve been advised of potential
              damages or if a remedy fails in its essential purpose. Our total
              liability is limited to $100, regardless of the cause or nature of
              the claim. Certain states may not allow limitations on liability,
              so these terms may not apply in full to you. In such cases, our
              liability will be limited to the fullest extent permitted by law.
            </p>
            <br />
            <h3 className="inter semiBold">13. Arbitration</h3>
            <p>
              By agreeing to these Terms, you consent to resolve any disputes
              with us through binding arbitration, which means you give up your
              right to pursue any claims through a lawsuit or class action.
              Arbitration will be conducted in the State of Delaware, following
              the rules of the American Arbitration Association (AAA). Both you
              and we waive the right to a trial by jury or to participate in any
              class or collective actions. The arbitrator has the exclusive
              authority to decide any issues related to the validity,
              interpretation, or enforceability of these Terms. The Federal
              Arbitration Act governs this agreement, and arbitration decisions
              are final and binding.
            </p>
            <br />
            <h3 className="inter semiBold">14. U.S. Jurisdiction</h3>
            <p>
              Our Services are operated from the United States, and we do not
              intend to subject ourselves to the jurisdiction of any laws
              outside the U.S. We make no guarantees that our Services are
              suitable for use outside the United States.
            </p>
            <br />
            <h3 className="inter semiBold">15. Governing Law</h3>
            <p>
              These Terms are governed by and will be interpreted in accordance
              with the laws of the State of Delaware, without consideration of
              its conflict of law principles.
            </p>
            <br />
            <h3 className="inter semiBold">16. Entire Agreement</h3>
            <p>
              These Terms, along with our Privacy Policy, represent the complete
              agreement between you and us regarding your use of our Services.
              They replace any prior discussions or agreements on the subject
              matter.
            </p>
            <br />
            <h3 className="inter semiBold">17. Assignment</h3>
            <p>
              You may not assign, transfer, or sublicense any of your rights or
              obligations under these Terms without our written permission. We
              may assign our rights and obligations at any time without
              notifying you.
            </p>
            <br />
            <h3 className="inter semiBold">18. Amendments to Terms</h3>
            <p>
              We reserve the right to update or change these Terms at any time.
              Changes will take effect once posted on our website, and it’s your
              responsibility to review them regularly. Continued use of our
              Services means you accept any modifications.
            </p>
            <br />
            <h3 className="inter semiBold">19. General Provisions</h3>
            <p>
              If we fail to enforce any part of these Terms, it does not waive
              our right to enforce them later. If any part of these Terms is
              found to be invalid, the rest will still apply. These Terms do not
              create any employment, partnership, or agency relationship. Any
              claims related to these Terms must be filed within 12 months after
              they arise. All notices must be in writing and are deemed
              effective once received. You may reach us at{" "}
              <a href="mailto:info@ovalife.me">info@ovalife.me</a> for any
              questions.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Terms;
