import React, { useEffect, useState } from "react";
import "./style.scss";
import { useLocation, useParams } from "react-router-dom";
import {
  renderContent,
  renderListContent,
  renderTextWithLineBreaks,
} from "./helper";
import moment from "moment";
import { getArticleDetail } from "../../../../../services/blogs";
import { config } from "../../../../../config";

const Detail = () => {
  const [getBlogsDetail, setGetBlogsDetail] = useState(null);
  const [isLoader, setIsLoader] = useState(true);
  const { id } = useParams();
  const { state } = useLocation();
  const category = state?.category;

  const getBlogDetail = async (id) => {
    const { status, res } = await getArticleDetail(id);
    setIsLoader(false);
    if (status) {
      const data = res.data;
      setGetBlogsDetail(data);
    }
  };

  useEffect(() => {
    getBlogDetail(id);
  }, [id]);

  return (
    <section className="section blog-detail">
      <div className="container">
        <div>
          <h2
            style={{
              marginBottom: "20px",
              marginTop: "50px",
              fontFamily: "TWK",
              textAlign: "center",
            }}
          >
            {getBlogsDetail?.attributes?.title}
          </h2>
          <img
            style={{ width: "100%", marginBottom: "30px" }}
            src={`${config.imgBaseUrl}${getBlogsDetail?.attributes?.background_image?.data?.attributes?.url}`}
            alt="background_image"
          />
        </div>
        <div className="Content">
          <p>{getBlogsDetail?.attributes?.description}</p>
          <div>
            {getBlogsDetail?.attributes?.blog_content?.map((content, index) => {
              switch (content.type) {
                case "paragraph":
                  return (
                    <p key={index}>
                      {content.children.map((child, childIndex) => (
                        <React.Fragment key={childIndex}>
                          {child.type === "text"
                            ? renderTextWithLineBreaks(child)
                            : renderContent(child)}
                        </React.Fragment>
                      ))}
                    </p>
                  );
                case "list":
                  return renderListContent(content, content.format, index);

                default:
                  return null;
              }
            })}
          </div>
          {category && <label>{category}</label>}
          <div className="d-flex">
            <div className="avatar">
              <img
                src={`${config.imgBaseUrl}${
                  getBlogsDetail?.attributes?.creator?.data?.attributes
                    ?.creator_image?.data?.attributes?.url ||
                  `${config.imgBaseUrl}/uploads/creator_logo_50e0b92492.png`
                }`}
                alt="creator_image"
              />
            </div>
            <div className="text">
              <h4>
                {
                  getBlogsDetail?.attributes?.creator?.data?.attributes
                    ?.creator_name
                }
              </h4>
              <p>
                {" "}
                {moment(getBlogsDetail?.attributes?.publishedAt).format(
                  "DD MMM YYYY"
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Detail;
