import React, { Suspense, useEffect, useState } from "react";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import { Route, Routes } from "react-router-dom";
import SkeletonLoader from "./components/loader";
import BookForm from "./components/bookForm/BookForm";
import SubmitPopup from "./components/submitPopup/SubmitPopup";
import Detail from "./pages/home/sections/blogs/detail";
import Privacy from "./pages/privacy/Privacy";
import Terms from "./pages/terms/Terms";
import AOS from "aos";
import "aos/dist/aos.css";

// Lazy load the components
const Home = React.lazy(() => import("./pages/home/Home"));

function App() {
  useEffect(() => {
    AOS.init({
      once: false,
      offset: 100,
    });
  }, []);
  const [showSidebar, setShowSidebar] = useState(false);
  const [isRecipient, setIsRecipient] = useState(true);
  const [isSubmitPopup, setIsSubmitPopup] = useState(false);

  const handleSidebar = () => {
    setShowSidebar(!showSidebar);
    if (showSidebar) {
      document.body.classList.remove("hide-scroll");
    } else {
      document.body.classList.add("hide-scroll");
    }
  };

  const handleClosePopup = async () => {
    setShowSidebar(false);
    document.body.classList.remove("hide-scroll");
    setIsSubmitPopup(true);

    setTimeout(() => {
      setIsSubmitPopup(false);
    }, 10000);
  };

  const handleToggleButton = () => {
    setIsRecipient(!isRecipient);
  };

  return (
    <>
      <BookForm
        showSidebar={showSidebar}
        handleSidebar={handleSidebar}
        handleClosePopup={handleClosePopup}
      />
      {isSubmitPopup && <SubmitPopup setIsSubmitPopup={setIsSubmitPopup} />}
      <Header
        isRecipient={isRecipient}
        handleToggleButton={handleToggleButton}
        handleSidebar={handleSidebar}
      />
      <div className={`main-content ${showSidebar ? "hide-scroll" : ""}`}>
        <Suspense fallback={<SkeletonLoader />}>
          <Routes>
            <Route
              path="/"
              element={
                <Suspense fallback={<SkeletonLoader />}>
                  <Home
                    isRecipient={isRecipient}
                    handleSidebar={handleSidebar}
                  />
                </Suspense>
              }
            />
            <Route
              path="/blog-detail/:id"
              element={
                <Suspense fallback={<SkeletonLoader />}>
                  <Detail />
                </Suspense>
              }
            />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
          </Routes>
        </Suspense>
      </div>
      <Footer />
    </>
  );
}

export default App;
