import React from "react";
import "./style.scss";

const PrimaryButton = ({
  aos,
  aosDelay,
  type,
  style,
  noIcon,
  className,
  isLoading,
  handleClick,
  primaryBtnIcon,
  primaryBtnText,
  primaryExternalLink,
}) => {
  const handleExternalClick = () => {
    window.open(primaryExternalLink, "_blank");
  };
  return (
    <button
      data-aos={aos}
      data-aos-delay={aosDelay}
      style={{ ...style, pointerEvents: isLoading ? "none" : "auto" }}
      type={type || "button"}
      disabled={isLoading}
      className={`primary-button inter medium ${className ? className : ""}`}
      onClick={() => {
        if (handleClick) {
          handleClick?.();
        } else if (primaryExternalLink) {
          handleExternalClick();
        }
      }}
    >
      {primaryBtnText} {noIcon ? "" : primaryBtnIcon}
    </button>
  );
};
export default PrimaryButton;
