import axios from "axios";
import { config } from "../config";

export const getArticles = async (page, pageSize = 10) => {
  try {
    const res = await axios.get(
      `${config.baseUrl}genome-blogs/?sort=createdAt:DESC&pagination[page]=${page}&pagination[pageSize]=${pageSize}&populate[0]=creator&populate[2]=creator.creator_image.media&populate[3]=categories&populate[4]=display_image&populate[5]=background_image `
    );
    return { status: true, res: res.data };
  } catch (error) {
    return { status: false };
  }
};

export const getArticleDetail = async (id) => {
  try {
    const res = await axios.get(
      `${config.baseUrl}genome-blogs/${id}?populate[0]=creator&populate[2]=creator.creator_image.media&populate[3]=categories&populate[4]=display_image&populate[5]=background_image`
    );
    return { status: true, res: res.data };
  } catch (error) {
    return { status: false };
  }
};
