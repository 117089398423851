import axios from "axios";
import { bookingConfig } from "../config";

export const createBooking = async (data) => {
  try {
    const res = await axios.post(`${bookingConfig.baseUrl}/contact-us`, data);
    return { status: true, res: res.data };
  } catch (error) {
    return { status: false };
  }
};
