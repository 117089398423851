import React from "react";
import styles from "./Privacy.module.scss";

const Privacy = () => {
  return (
    <>
      <section className={`section ${styles.heroSection}`}>
        <div className="container">
          <div className={styles.heroContent}>
            <h5 className="inter medium">Last updated: October 14th, 2024</h5>
            <h2>Privacy Policy</h2>
            {/* <p>
              We use some cookies to help improve your experience on this
              website. Here's some more info about what we do with information
              we collect.
            </p> */}
          </div>
        </div>
      </section>
      <section className={`section ${styles.contentSection}`}>
        <div className="container">
          <div className={styles.content}>
            <p className="inter regular">
              Ovalife (“OL,” “we,” “us,” or “our”) is committed to protecting
              your privacy. The terms “you,” “your,” or “user(s)” refer to
              individuals who use our services, which encompass all information,
              tools, and offerings available on our platform. This Privacy
              Policy describes the types of information we collect, the reasons
              for gathering it, how we use it to improve our services, and your
              options regarding your information. This policy applies to all
              users of our services and is part of our Terms of Use, accessible
              at Terms of Use.
              <br />
              <br />
              This document details the Personal Information we collect through
              our services, the rationale behind its collection, how we use it,
              and when we may share it with third parties. Additionally, it
              outlines your choices concerning the collection and use of your
              information. The Personal Information you provide will be used
              solely for the purposes described herein.
              <br />
              <br />
              By accessing and utilizing our website, you agree to the
              collection, storage, use, and disclosure of your Personal
              Information as detailed in this Privacy Policy. If you do not
              agree with these terms, please do not provide any Personal
              Information. Not providing necessary Personal Information may
              restrict our ability to offer certain products or services to you.
              <br />
              <br />
              Under the Health Insurance Portability and Accountability Act of
              1996 (HIPAA) and its amendments, some demographic, health, and
              related information we collect may be classified as “protected
              health information” or “PHI.” When we receive identifiable
              information from your healthcare providers, that information is
              deemed PHI. HIPAA imposes strict regulations on the privacy and
              security of PHI and restricts its use and disclosure. We will only
              use your PHI in compliance with HIPAA and the agreements made with
              your healthcare providers.
            </p>
            <br />
            <h3 className="inter semiBold">1. Information We Collect</h3>
            <p>
              <b>a. Personal Information</b>
              <br />
              We collect Personal Information about our users. “Personal
              Information” refers to any data that can identify you or others
              associated with your activities on our services. This includes,
              but is not limited to:
            </p>
            <ul>
              <li>
                <p>First and last name</p>
              </li>
              <li>
                <p>Phone number</p>
              </li>
              <li>
                <p>Home address</p>
              </li>
              <li>
                <p>Feedback and comments</p>
              </li>
              <li>
                <p>Employment information</p>
              </li>
              <li>
                <p>Title and educational history</p>
              </li>
              <li>
                <p>Health information</p>
              </li>
              <li>
                <p>Ethnicity and nationality</p>
              </li>
              <li>
                <p>Age, height, weight, eye color, and hair color</p>
              </li>
              <li>
                <p>Username, email address, and passwords</p>
              </li>
              <li>
                <p>
                  Payment details, such as credit/debit card numbers (excluding
                  security codes)
                </p>
              </li>
            </ul>
            <br />
            <p>
              <b>b. Aggregated Information</b>
              <br />
              We may combine the data from the Personal Information we collect,
              removing any details that could personally identify you, to create
              “Aggregated Information.”
            </p>
            <br />
            <p>
              <b>c. Cookies and Pixel Tags</b>
              <br />
              We may use cookies and pixel tags to gather user information.
              Cookies are small data files sent to your device while you
              navigate our website. We utilize session cookies (which expire
              when your browser is closed), persistent cookies (which remain
              until deleted), and third-party cookies (from domains other than
              ours) to enhance your experience. Pixel tags are snippets of code
              that help us track user behavior on our site.
            </p>
            <br />
            <p>
              <b>d. Device Identifiers</b>
              <br />
              Like many web providers, we automatically gather certain
              information from your devices, such as your IP address, browser
              type, unique device identifier (UDID), and mobile operating
              system.
            </p>
            <br />
            <p>
              <b>e. Information You Provide</b>
              <br />
              We collect information that you provide directly to us, which may
              occur when you:
            </p>
            <ul>
              <li>
                <p>Request a product demonstration</p>
              </li>
              <li>
                <p>Subscribe to our newsletters</p>
              </li>
              <li>
                <p>Engage in our services, such as completing questionnaires</p>
              </li>
              <li>
                <p>
                  Communicate with us through various channels (e.g., email,
                  phone, social media)
                </p>
              </li>
              <li>
                <p>Apply for a job with OL</p>
              </li>
            </ul>
            <br />
            <p>
              <b>f. Technical Information</b>
              <br />
              We may collect technical details about your usage of our website,
              including browser type, operating system, and IP address, via
              cookies and other technologies.
            </p>
            <br />
            <p>
              <b>g. Payment Information</b>
              <br />
              We gather payment processing information as described in section
              1(a). We may utilize third-party services for payment processing
              and recommend not sending sensitive financial information via
              email.
            </p>
            <br />
            <p>
              <b>h. Geolocation Data</b>
              <br />
              If you access our services via a desktop or mobile device, we may
              collect and store geolocation data.
            </p>
            <br />
            <p>
              <b>i. Information from Third Parties</b>
              <br />
              We may obtain information from third-party sources to enrich the
              information we gather about you. This can include data from your
              healthcare provider, identity verification services, and social
              media platforms if you log in through them.
            </p>
            <br />
            <h3 className="inter semiBold">2. Use of Your Information</h3>
            <p>
              <b>a. Personal Information</b>
              <br />
              We may use your Personal Information for various purposes,
              including:
            </p>
            <ul>
              <li>
                <p>Reviewing and processing your Donor Questionnaire</p>
              </li>
              <li>
                <p>
                  Creating and managing your Donor Profile for our healthcare
                  provider networks and prospective parents
                </p>
              </li>
              <li>
                <p>Sending you communications and administrative emails</p>
              </li>
              <li>
                <p>
                  Fulfilling contractual obligations and providing requested
                  services
                </p>
              </li>
              <li>
                <p>Sending marketing and promotional materials</p>
              </li>
              <li>
                <p>
                  Conducting internal operations, including fraud prevention and
                  legal compliance
                </p>
              </li>
              <li>
                <p>Personalizing your experience and improving our services</p>
              </li>
            </ul>
            <br />
            <p>
              <b>b. Aggregated Information</b>
              <br />
              We may use Aggregated Information for internal operations,
              enhancing the website, and analyzing trends.
            </p>
            <br />
            <p>
              <b>c. Targeting and Advertising</b>
              <br />
              We may use your information to improve the targeting and
              performance measurement of advertisements, both on our site and
              through third-party networks.
            </p>
            <br />
            <h3 className="inter semiBold">
              3. Legal Basis for Processing Your Information
            </h3>
            <p>
              Our processing of your Personal Information is based on one or
              more of the following legal grounds:
            </p>
            <ul>
              <li>
                <p>
                  Performing a contract with you or taking steps prior to
                  entering into a contract
                </p>
              </li>
              <li>
                <p>Protecting your or another individual's vital interests</p>
              </li>
              <li>
                <p>Complying with legal obligations</p>
              </li>
              <li>
                <p>
                  Pursuing our legitimate interests, such as enhancing our
                  services and ensuring security
                </p>
              </li>
            </ul>
            <br />
            <h3 className="inter semiBold">
              4. Sharing and Disclosure of Your Personal Information
            </h3>
            <p>
              <b>a. Third-Party Sharing</b>
              <br />
              We will not sell or share your Personal Information with third
              parties except as specified in this policy. If you prefer not to
              allow us to use or disclose your Personal Information as
              described, please refrain from using our services.
            </p>
            <br />
            <p>
              <b>b. Healthcare Provider Disclosure</b>
              <br />
              To use our services, you must have an account with a participating
              healthcare provider; consequently, your Personal Information may
              be shared with them according to their privacy policies.
            </p>
            <br />
            <p>
              <b>c. Service Providers</b>
              <br />
              We may share your Personal Information with third-party service
              providers who assist us in delivering our products and services
              while ensuring confidentiality.
            </p>
            <br />
            <p>
              <b>d. Legal Requirements</b>
              <br />
              We may disclose your information if legally obligated to do so or
              if we believe it is necessary to protect our rights or comply with
              applicable laws.
            </p>
            <br />
            <h3 className="inter semiBold">5. Usage of Aggregated Data</h3>
            <p>
              a. We utilize aggregated data to operate and manage the website,
              analyze patterns, gather demographic information, and comply with
              legal requirements. This aggregated data may be shared with third
              parties without needing to notify or obtain your consent, and,
              where permitted by law, we can use and disclose this data without
              restrictions. Some service providers may also use this information
              for their business purposes, following their privacy policies, for
              example, reporting usage trends.
              <br />
              b. Our website may use cookies to improve your experience. Cookies
              help us recognize you upon return visits, store preferences, and
              collect statistical information about your interactions with the
              website, such as which pages you visit or which sites you accessed
              before and after ours. This helps us analyze your activity and
              better understand which parts of our site are most popular. We may
              allow affiliates, service providers, or advertisers to use cookies
              for analytics and research purposes. Some advertisers may belong
              to networks that offer tools to opt out of targeted ads. For more
              information, visit{" "}
              <a
                href="https://thenai.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.networkadvertising.org
              </a>
              .
              <br />
              c. We may use pixel tags to monitor user behavior and measure the
              effectiveness of our website, marketing, and advertising. Pixel
              tags, or clear GIFs, function similarly to cookies by tracking
              online activity, but they are embedded within websites instead of
              being stored on your device. These tags help us gather
              non-personal data about site performance, and we may combine such
              information with personal data when appropriate. We collaborate
              with service providers to collect and analyze this information.
              <br />
              d. Cookies, pixel tags, and similar tracking tools collect details
              about your visit, such as the pages you view, features you use,
              links you click, and other actions. This data may include
              technical details like your IP address, browser type, and
              operating system, as well as date and time information. We may
              also track the website you visited before arriving at our site.
              Tracking technologies may also be used in our emails to monitor
              your engagement with those messages. Additionally, third-party
              companies may use these technologies to serve ads on our behalf
              across the web, although no personal data is shared with these
              entities.
              <br />
              e. Information collected via cookies and similar tools may be
              combined with other data we gather from you. This combined data is
              used to enhance the website, personalize your experience, and
              assess advertising effectiveness. Aggregated and anonymized data
              may also be shared with our business partners for research or
              marketing purposes. We may freely share this non-personal data
              with third parties, such as service providers, to help them
              deliver services to us.
              <br />
              f. The website may offer links or third-party functionalities like
              social media plugins. Before using these features, you should
              review the privacy policies of the third-party providers, as their
              data practices may differ from ours. We make no guarantees about
              how these third parties handle your personal information, and any
              interactions you have with them are at your own risk.
            </p>
            <br />
            <h3 className="inter semiBold">6. Data Retention</h3>
            <p>
              a. We will retain your personal data for as long as necessary to
              fulfill the purposes outlined in this privacy policy or as
              required by law, including resolving disputes and protecting our
              legal rights.
              <br />
              b. When processing personal data based on legitimate interests, we
              will retain the information for a reasonable period, balancing our
              needs with your rights and freedoms.
              <br />
              c. When processing data based on your consent, we will retain it
              only for as long as necessary for the purpose for which you gave
              consent, subject to your right to request erasure under certain
              circumstances (see Section 8).
              <br />
              d. If your data is processed under a contractual obligation, we
              will retain it for the duration of the contract and any additional
              period required by law or for legal claims that might arise from
              the contract.
            </p>
            <br />
            <h3 className="inter semiBold">7. Opting-Out</h3>
            <p>
              a. <b>Unsubscribe:</b> You may opt out of receiving promotional
              emails by following the unsubscribe instructions at the bottom of
              each email or by emailing us at{" "}
              <a href="mailto:info@ovalife.me">info@ovalife.me</a> with
              “UNSUBSCRIBE” in the subject line. Please note that unsubscribing
              from promotional emails will not stop service-related or
              administrative communications, such as order confirmations or
              policy updates. If you do not receive a response to your opt-out
              request within 10 business days, please resend your request as it
              may not have been received.
              <br />
              b. <b>Google Analytics, Facebook, and Apple:</b> We may use Google
              Analytics, Facebook, and Apple services for ad targeting and
              analytics. You can opt out of Google’s ad settings at{" "}
              <a
                href="https://adssettings.google.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://adssettings.google.com
              </a>{" "}
              and their analytics tool at{" "}
              <a
                href="https://tools.google.com/dlpage/gaoptout"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://tools.google.com/dlpage/gaoptout
              </a>
              . Facebook’s privacy settings are at{" "}
              <a
                href="https://www.facebook.com/about/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.facebook.com/about/privacy
              </a>
              , and Apple’s privacy policy can be found at{" "}
              <a
                href="https://www.apple.com/privacy/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.apple.com/privacy/privacy-policy
              </a>
              .
              <br />
              c. <b>Ad Industry Opt-Outs:</b> You can opt out of interest-based
              ads through TRUSTe’s Ad Preference Manager or the Digital
              Advertising Alliance at{" "}
              <a
                href="http://www.aboutads.info/choices"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://www.aboutads.info/choices
              </a>
              .
              <br />
              d. <b>Cookies:</b> You can manage or disable cookies via your
              browser settings. If you disable cookies, certain features of the
              website may be unavailable to you, such as automatically logging
              in.
            </p>
            <br />
            <h3 className="inter semiBold">
              8. Continued Use of Personal Information
            </h3>
            <p>
              Even if you request the deletion of your personal information, we
              may retain it where we have a legal basis, such as to comply with
              legal obligations or protect our legitimate interests.
              Additionally, if your information has already been shared with
              third parties, we cannot control how they handle it or ensure its
              deletion.
            </p>
            <br />
            <h3 className="inter semiBold">
              9. Notice to California Residents About Your Privacy Rights
            </h3>
            <p>
              a. <b>Shine the Light:</b> Under California Civil Code Section
              1798.83, commonly referred to as the "Shine The Light" law,
              residents of California have the right to request in writing from
              businesses with which they maintain a business relationship: (i) a
              list of categories of Personal Information—such as name, address,
              email address, and the services provided—that a business has
              disclosed to third parties (including affiliates that are separate
              legal entities) for direct marketing purposes in the previous
              calendar year, and (ii) the names and addresses of those third
              parties. Ovalife (OL) does not share Personal Information with
              third parties for their direct marketing purposes. To request this
              information, California residents can email us at{" "}
              <a href="mailto:legal@eggdonorconnect.com">
                legal@eggdonorconnect.com
              </a>{" "}
              or send a written request to Ovalife, Attn: Gattaca Genomics 2425
              E Commercial Blvd. Suite 400, Fort Lauderdale, FL 33308. United
              States. Please be aware that, per California law, a business is
              only required to respond to such requests twice each calendar
              year.
              <br />
              b. <b>California Consumer Privacy Act:</b> The California Consumer
              Privacy Act (CCPA), Cal. Civ. Code §1798.100 et seq., provides
              California residents with specific rights regarding their Personal
              Information and mandates that we furnish such individuals with
              certain information as detailed in this section.
              <br />
              i. <b>Your Rights:</b> California residents can exercise the
              following rights by contacting us by emailing{" "}
              <a href="mailto:info@ovalife.me">info@ovalife.me</a>, or as
              outlined at the end of this document:
              <ul>
                <li>
                  <p>
                    Understand how we collect, use, share, disclose, and
                    otherwise process your Personal Information;
                  </p>
                </li>
                <li>
                  <p>
                    Access the specific pieces of your Personal Information that
                    we hold;
                  </p>
                </li>
                <li>
                  <p>
                    Request the deletion of your Personal Information, subject
                    to certain exceptions;
                  </p>
                </li>
                <li>
                  <p>
                    Be assured that you will not be denied goods or services for
                    exercising these rights.
                  </p>
                </li>
              </ul>
              c.{" "}
              <b>Requesting Access to or Deletion of Personal Information:</b>{" "}
              California residents have the right, with certain exceptions
              defined by the CCPA and other applicable laws, to request
              information about our collection and use of their Personal
              Information over the last twelve (12) months. To verify your
              request, you must provide sufficient information for us to
              identify you individually, including the name you used when
              creating your account (i.e., your legal first and last name or
              variations thereof, such as nicknames, aliases, or titles), the
              email address(es) used for correspondence with us, and your
              mailing address, including the state and city of residence. You
              can make a request for access or deletion by emailing
              <a href="mailto:info@ovalife.me">info@ovalife.me</a>.
              <br />
              d. <b>Disclosure Notices:</b> Depending on your usage of our
              Services, we have disclosed the following categories of Personal
              Information for a "business purpose" (as defined in the CCPA) in
              the past twelve (12) months:
              <ul>
                <li>
                  <p>
                    First name, last name, image or likeness, email address,
                    phone number, street address, comments, company name and
                    address, title, work experience, skills, username and/or
                    email address in combination with a password or answers to
                    security questions, account numbers or credit/debit card
                    numbers (even without security codes), access codes, or
                    passwords if the account is accessible without this
                    information;
                  </p>
                </li>
                <li>
                  <p>Geolocation data;</p>
                </li>
                <li>
                  <p>Non-Personal Information;</p>
                </li>
                <li>
                  <p>Internet or electronic network activity information;</p>
                </li>
                <li>
                  <p>Audio, electronic, visual, or similar information;</p>
                </li>
                <li>
                  <p>
                    Inferences drawn from any of the aforementioned information.
                  </p>
                </li>
              </ul>
              e. <b>Non-Discrimination:</b> We will not discriminate against you
              for exercising any rights under California law, including:
              <br />
              <ul>
                <li>
                  <p>Denying you goods or services;</p>
                </li>
                <li>
                  <p>
                    Charging you different prices or rates for goods or
                    services, including through discounts or other benefits, or
                    imposing penalties;
                  </p>
                </li>
                <li>
                  <p>
                    Providing you with a different level or quality of goods or
                    services;
                  </p>
                </li>
                <li>
                  <p>
                    Suggesting that you may receive a different price or rate
                    for goods or services, or a different level or quality of
                    goods or services.
                  </p>
                </li>
              </ul>
              f. <b>Do Not Track:</b> Some web browsers may offer a "do not
              track" feature that sends signals to websites you visit,
              indicating that you do not wish for your online activities to be
              tracked. This is distinct from blocking or deleting cookies, as
              browsers with a "do not track" feature enabled may still accept
              cookies. Currently, there is no industry standard for how
              companies should respond to "do not track" signals, although one
              may emerge in the future. At this time, we do not respond to "do
              not track" requests or signals.
            </p>
            <br />
            <h3 className="inter semiBold">10. Children</h3>
            <p>
              This Website is not aimed at children under the age of 13. We do
              not knowingly solicit, collect, or maintain information from
              individuals we are aware are under 13, and no part of our Website
              is designed to attract anyone under this age. We also do not send
              email correspondence to anyone who informs us they are under 13.
              If we become aware that a user is under 13, we will take steps to
              delete that user's Personal Information from our systems. If you
              are a parent or guardian of a child you believe has shared
              Personal Information with us, please contact us at{" "}
              <a href="mailto:info@ovalife.me">info@ovalife.me</a>
              or write to Gattaca Genomics 2425 E Commercial Blvd. Suite 400,
              Fort Lauderdale, FL 33308, so we can delete and remove such
              information from our systems.
            </p>
            <br />
            <h3 className="inter semiBold">11. Security</h3>
            <p>
              We are committed to keeping your Personal Information secure and
              private. We take commercially reasonable physical, electronic, and
              administrative measures to protect the Personal Information we
              collect. This includes limiting access to database servers and
              using electronic security systems and password protections to
              prevent unauthorized access. However, despite our best efforts, we
              cannot guarantee that data transmitted over the Internet will be
              100% secure. While we strive to ensure the security of information
              you send through our Website, any transmission of Personal
              Information is done at your own risk. We cannot assure that your
              information will not be intercepted by third parties, and we are
              not liable for any breach of security concerning your Personal
              Information due to factors beyond our control, including but not
              limited to your actions, media corruption, defects in third-party
              security measures, power failures, natural disasters, riots, acts
              of vandalism, hacking, sabotage, or terrorism. We are not
              responsible for any circumvention of privacy settings or security
              measures on the Website.
            </p>
            <br />
            <h3 className="inter semiBold">12. Third-Party Websites</h3>
            <p>
              While using our Website, you may encounter links to websites
              operated by third parties, or you may arrive at our Website
              through links from other sites. We do not make any guarantees or
              assurances about these third-party websites, as we have no control
              over their content or the information, services, or products they
              provide, nor how they collect, store, or use your Personal
              Information. If you provide Personal Information on those
              websites, you are subject to the privacy policies of their
              respective operators, not ours. Please review the privacy policies
              of any other websites before submitting your Personal Information.
            </p>
            <br />
            <h3 className="inter semiBold">
              13. Changes to Our Privacy Policy
            </h3>
            <p>
              We reserve the right to modify, update, or remove portions of this
              Privacy Policy at any time without prior notice, with changes
              taking effect immediately upon posting. However, we will not use
              your Personal Information in a manner that is adverse and
              materially different from what is described in this Privacy Policy
              without giving you an opportunity to opt out. The “Last Modified”
              date indicates when changes have been made to this Privacy Policy.
              Your continued use of the Website after any amendments to this
              Privacy Policy signifies your acceptance of the updated terms and
              conditions.
            </p>
            <br />
            <h3 className="inter semiBold">14. Data Location</h3>
            <p>We store data within U.S. data centers.</p>
            <br />
            <h3 className="inter semiBold">15. Contact Us</h3>
            <p>
              If you have any questions regarding our Privacy Policy or wish to
              exercise your rights as applicable by law, please contact us via
              email at <a href="mailto:info@ovalife.me">info@ovalife.me</a> or
              write to Gattaca Genomics 2425 E Commercial Blvd. Suite 400, Fort
              Lauderdale, FL 33308
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Privacy;
