import React, { useState, useEffect } from "react";
import styles from "./BookForm.module.scss";
import PrimaryButton from "../../common/primaryButton";
import { createBooking } from "../../services/booking";
import { countries } from "../../config/countryConfig";

const BookForm = ({ showSidebar, handleSidebar, handleClosePopup }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
  });

  const [loading, setLoading] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^[0-9]{10,15}$/;
    return phoneRegex.test(phone);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Clear errors on input change
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Simple validation
    let isValid = true;
    let newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
      isValid = false;
    }
    if (!formData.email.trim() || !validateEmail(formData.email)) {
      newErrors.email = "A valid email is required";
      isValid = false;
    }
    if (!formData.phone.trim() || !validatePhone(formData.phone)) {
      newErrors.phone = "A valid phone number is required";
      isValid = false;
    }
    if (!formData.country.trim()) {
      newErrors.country = "Country of residence is required";
      isValid = false;
    }

    setErrors(newErrors);

    if (isValid) {
      setLoading(true);
      const response = await createBooking(formData);

      if (response.status) {
        setLoading(false);
        resetForm();
        handleClosePopup();
      } else {
        setLoading(false);
        alert("Failed to submit the form. Please try again.");
      }
    }
  };

  const resetForm = () =>
    setFormData({ name: "", email: "", phone: "", country: "", message: "" });

  useEffect(() => {
    const sidebarWrapper = document.getElementById("sidebarWrapper");
    sidebarWrapper.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [showSidebar]);

  return (
    <div
      className={`${styles.wrapper} ${showSidebar ? styles.show : ""}`}
      id="sidebarWrapper">
      <div className={styles.header}>
        <h4 className="inter medium">Contact us</h4>
        <div className={styles.close} onClick={handleSidebar}>
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.4 14L0 12.6L5.6 7L0 1.4L1.4 0L7 5.6L12.6 0L14 1.4L8.4 7L14 12.6L12.6 14L7 8.4L1.4 14Z"
              fill="#1D1B20"
            />
          </svg>
        </div>
      </div>
      <h2>Talk to a Specialist</h2>
      <p className="inter regular">
        Can't find what you're looking for? Please contact us and we'll get back
        to you as soon as possible.
      </p>
      <form onSubmit={handleSubmit}>
        <div className={styles.input}>
          <input
            className="inter regular"
            type="text"
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleInputChange}
          />
        </div>
        {errors.name && <p className={styles.error}>{errors.name}</p>}
        <div className={styles.input}>
          <input
            className="inter regular"
            type="email"
            name="email"
            placeholder="Email address"
            value={formData.email}
            onChange={handleInputChange}
          />
        </div>
        {errors.email && <p className={styles.error}>{errors.email}</p>}
        <div className={styles.input}>
          <input
            className="inter regular"
            type="phone"
            name="phone"
            placeholder="Phone number"
            value={formData.phone}
            onChange={handleInputChange}
          />
        </div>
        {errors.phone && <p className={styles.error}>{errors.phone}</p>}
        <div className={styles.input}>
          <select
            className="inter regular"
            name="country"
            value={formData.country}
            onChange={handleInputChange}>
            <option value="">Select your country</option>
            {countries.map((country, index) => (
              <option key={index} value={country}>
                {country}
              </option>
            ))}
          </select>
        </div>
        {errors.country && <p className={styles.error}>{errors.country}</p>}
        <div className={styles.input}>
          <textarea
            className="inter regular"
            name="message"
            id=""
            rows={8}
            placeholder="Additional notes or questions"
            value={formData.message}
            onChange={handleInputChange}
          />
        </div>
        <PrimaryButton
          primaryBtnText="Submit"
          noIcon
          type="submit"
          isLoading={loading}
        />
      </form>
    </div>
  );
};

export default BookForm;
