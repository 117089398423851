import React from "react";

export const renderContent = (content) => {
  switch (content.type) {
    case "text":
      return content?.bold ? (
        <b>{content.text}</b>
      ) : content?.italic ? (
        <i>{content.text}</i>
      ) : (
        content.text
      );
    case "link":
      return <a href={content.url}>{content.children[0].text}</a>;
    case "bold":
      return <strong>{content.text}</strong>;
    default:
      return null;
  }
};

export const renderTextWithLineBreaks = (content) => {
  return content.text.split("\n").map((line, index) => (
    <React.Fragment key={index}>
      {content?.bold ? <b>{line}</b> : content?.italic ? <i>{line}</i> : line}
      <br />
    </React.Fragment>
  ));
};

export const renderListContent = (content, listType, index) => {
  if (listType === "ordered") {
    return (
      <ol key={index} type="1"  style={{padding:'20px'}}>
        {content.children.map((item, itemIndex) => (
          <li key={itemIndex} style={{marginBottom:'10px', lineHeight:'1.5'}}>
            {item.children.map((child) => child.text).join("")}
          </li>
        ))}
      </ol>
    );
  }

  return (
    <ul key={index} style={{padding:'20px'}}>
      {content.children.map((item, itemIndex) => (
        <li key={itemIndex} style={{marginBottom:'10px', lineHeight:'1.5'}}>
          {item.children.map((child) => child.text).join("")}
        </li>
      ))}
    </ul>
  );
};